$btnBoxShadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
$slimLr: 14px;

.btn,
.btn:visited {
    display: inline-block;
    border-radius: 3px;
    box-shadow: $btnBoxShadow;
    font-weight: $weight--medium;
    padding: 12px 54px;
    border: 0;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    transition: all 200ms ease;

    &:hover {
        text-decoration: none;
    }
}

.btn--slim {
    padding: 10px $slimLr;
}

.btn--slim--lr {
    padding-left: $slimLr;
    padding-right: $slimLr;
}

.btn--block {
    width: 100%;
}

.btn--large {
    font-size: 18px;
    font-weight: $weight--medium;
}

.btn--white,
.btn--white:visited {
    box-shadow: none;
    color: $c--body--text;
    padding: 12px 10px;
    background: white;
    border: 1px solid $c--blue--darker;

    &:hover {
        color: $c--body--text;
        background: $c--blue--light;
    }
}

.btn--blue--light,
.btn--blue--light:visited {
    color: white;
    background: #3177CA;
    font-style: italic;

    &:hover {
        color: white;
        background: $c--blue--light;
    }
}

.btn--blue--dark,
.btn--blue--dark:visited {
    color: white;
    background: $c--blue--darker;

    &:hover {
        color: white;
        background: $c--blue--light;
    }
}

.btn--yellow,
.btn--yellow:visited {
    color: $c--blue--darker;
    background: $c--yellow;

    &:hover {
        color: $c--blue--darker;
        background: $c--yellow--light;
    }
}

.btn--cancel,
.btn--cancel:visited {
    color: white;
    background: #9295A1;
    font-style: italic;

    &:hover {
        color: white;
        background: #C5C6C9;
    }
}

.btn--disabled, /* just like btn:disabled, except doesn't have pointer-events set to none (basically just a visual/UX difference) */
.btn--disabled:visited,
.btn:disabled {
    background: #E1E2E6;
    color: $c--gray--dark;

    &:hover {
        color: $c--gray--dark;
        box-shadow: $btnBoxShadow; /* keep the box-shadow */
    }
}

.btn:disabled {
    cursor: not-allowed;
}

/* removes common button styling */
button.m--no-btn {
    border: 0;
    padding: 0;
    background: none;
}
