.c--photo-flag {
    position: relative;
    overflow: hidden;
}

.c--photo-flag::after {
    position: absolute;
    font-weight: $weight--bold;
    left: -18px;
    top: 11px;
    transform: rotate(-45deg);
    font-size: 12px;
    padding: 0 25px;
    z-index: 0;
}

.c--photo-flag.m--flag-new::after {
    content: 'NEW';
    color: $c--blue--darker;
    background: $c--yellow;
}

.c--photo-flag.m--flag-sale::after {
    content: 'NEW';
    color: $c--blue--darker;
    background: $c--yellow;
}
