/* NOTE: The Cybersource form fields are styled in client/components/FormCybersourceField.js */

form {
    .flex-microform,
    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='url'],
    select,
    textarea {
        display: block;
        width: 100%;
        max-width: 100%;
        padding: $input--padding--tb $input--padding--lr;
        border: $input--border--width solid $c--blue--dark;
        border-radius: $input--border--radius;
        font-size: $input--size;
        color: $c--body--text;
        background: white;
        box-shadow: 0 1px 2px -1px white, inset 0 1px 3px 0 $c--gray--dark;

        &.flex-microform-disabled,
        &:disabled {
            cursor: not-allowed;
            border-color: $c--gray--medium;
            color: #A1A3A7;
        }
    }

    .input-line {
        display: flex;
    }

    .description {
        font-style: italic;
    }

    .react-select__control {
        --boxShadowColorInner: white;
        --boxShadowColorOuter: $c--gray--dark;

        border: $input--border--width solid $c--blue--dark;
        border-radius: $input--border--radius;
        box-shadow: 0 1px 2px -1px var(--boxShadowColorInner), inset 0 1px 3px 0 var(--boxShadowColorOuter);

        &:hover,
        &.react-select__control--is-focused {
            --boxShadowColorInner: $c--yellow;
            --boxShadowColorOuter: $c--yellow;
            border-color: $c--yellow;
        }
    }

    .react-select__value-container {
        padding: 5px 8px; /* need this to make a react-select the same height as a normal input box */
    }

    /* react-select has a somewhat hidden input element, but with dimension. it should not have any visible styles on it. */
    .react-select .react-select__input input {
        box-shadow: none;
    }

    input::placeholder {
        font-style: italic;
    }

    .field {
        margin-bottom: 14px;

        &.has-errors {
            input[type='text'],
            input[type='email'],
            input[type='number'],
            input[type='password'],
            input[type='tel'],
            input[type='url'],
            select,
            textarea {
                border-color: $c--error;
            }

            input::placeholder {
                font-style: italic;
            }

            label {
                color: $c--error;
            }

            ul.errors {
                list-style: none;
                padding: 0;
                margin: 5px 0 10px 0;
                color: $c--error;
                font-style: italic;
            }
        }
    }

    .form--note {
        font-size: $input--size;
        font-weight: $weight--bold;
        padding: 3px 8px;
        display: block;
        max-width: 50%;
        margin: auto 0;
    }

    .form--note--after {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .form--note--before {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .field--price {
        .input-line {
            position: relative;

            &:before {
                content: '$';
                display: block;
                font-weight: $weight--bold;
                position: absolute;
                left: 7px;
                top: calc(50% - 10px);
            }

            input {
                font-weight: $weight--bold;
                padding-left: 17px; /* give room for the dollar sign */
            }
        }
    }

    .field-checkbox,
    .field-checkbox-list {
        .field-input {
            margin-top: 8px;
        }

        label {
            padding-left: 5px;
        }

        input {
            position: relative;
            top: 1px;
        }

        .field-checkbox-item {
            margin-bottom: 8px;
        }
    }
}
