@import url(https://p.typekit.net/p.css?s=1&k=icq3pyl&ht=tk&f=15493.15495&a=3703564&app=typekit&e=css);


        form .field-checkbox .field-checkbox-item, form .field-checkbox-list .field-checkbox-item {
            margin-bottom: 8px;
        }

        form .field-checkbox input, form .field-checkbox-list input {
            position: relative;
            top: 1px;
        }

        form .field-checkbox label, form .field-checkbox-list label {
            padding-left: 5px;
        }
        form .field-checkbox .field-input, form .field-checkbox-list .field-input {
            margin-top: 8px;
        }

            form .field--price .input-line input {
                font-weight: 700;
                padding-left: 17px; /* give room for the dollar sign */
            }

            form .field--price .input-line:before {
                content: '$';
                display: block;
                font-weight: 700;
                position: absolute;
                left: 7px;
                top: calc(50% - 10px);
            }
        form .field--price .input-line {
            position: relative
        }

    form .form--note--before {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    form .form--note--after {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    form .form--note {
        font-size: 0.9375rem;
        font-weight: 700;
        padding: 3px 8px;
        display: block;
        max-width: 50%;
        margin: auto 0;
    }

            form .field.has-errors ul.errors {
                list-style: none;
                padding: 0;
                margin: 5px 0 10px 0;
                color: #D0021B;
                font-style: italic;
            }

            form .field.has-errors label {
                color: #D0021B;
            }

            form .field.has-errors input::placeholder {
                font-style: italic;
            }

            form .field.has-errors input::-ms-input-placeholder {
                font-style: italic;
            }

            form .field.has-errors input:-ms-input-placeholder {
                font-style: italic;
            }

            form .field.has-errors input::-moz-placeholder {
                font-style: italic;
            }

            form .field.has-errors input::-webkit-input-placeholder {
                font-style: italic;
            }
            form .field.has-errors input[type='text'],
            form .field.has-errors input[type='email'],
            form .field.has-errors input[type='number'],
            form .field.has-errors input[type='password'],
            form .field.has-errors input[type='tel'],
            form .field.has-errors input[type='url'],
            form .field.has-errors select,
            form .field.has-errors textarea {
                border-color: #D0021B;
            }

    form .field {
        margin-bottom: 14px
    }

    form input::placeholder {
        font-style: italic;
    }

    form input::-ms-input-placeholder {
        font-style: italic;
    }

    form input:-ms-input-placeholder {
        font-style: italic;
    }

    form input::-moz-placeholder {
        font-style: italic;
    }

    form input::-webkit-input-placeholder {
        font-style: italic;
    }

    /* react-select has a somewhat hidden input element, but with dimension. it should not have any visible styles on it. */
    form .react-select .react-select__input input {
        -webkit-box-shadow: none;
                box-shadow: none;
    }

    form .react-select__value-container {
        padding: 5px 8px; /* need this to make a react-select the same height as a normal input box */
    }

        form .react-select__control:hover,
        form .react-select__control.react-select__control--is-focused {
            --boxShadowColorInner: #FFDD15;
            --boxShadowColorOuter: #FFDD15;
            border-color: #FFDD15;
        }

    form .react-select__control {
        --boxShadowColorInner: white;
        --boxShadowColorOuter: #666C82;

        border: 1px solid #4B62A0;
        border-radius: 3px;
        -webkit-box-shadow: 0 1px 2px -1px var(--boxShadowColorInner), inset 0 1px 3px 0 var(--boxShadowColorOuter);
                box-shadow: 0 1px 2px -1px var(--boxShadowColorInner), inset 0 1px 3px 0 var(--boxShadowColorOuter)
    }

    form .description {
        font-style: italic;
    }

    form .input-line {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

        form .flex-microform.flex-microform-disabled,
        form .flex-microform:disabled,
        form input[type='text'].flex-microform-disabled,
        form input[type='text']:disabled,
        form input[type='email'].flex-microform-disabled,
        form input[type='email']:disabled,
        form input[type='number'].flex-microform-disabled,
        form input[type='number']:disabled,
        form input[type='password'].flex-microform-disabled,
        form input[type='password']:disabled,
        form input[type='search'].flex-microform-disabled,
        form input[type='search']:disabled,
        form input[type='tel'].flex-microform-disabled,
        form input[type='tel']:disabled,
        form input[type='url'].flex-microform-disabled,
        form input[type='url']:disabled,
        form select.flex-microform-disabled,
        form select:disabled,
        form textarea.flex-microform-disabled,
        form textarea:disabled {
            cursor: not-allowed;
            border-color: #CCD0E0;
            color: #A1A3A7;
        }

        .m--list li:last-child {
            margin-bottom: 0;
        }
/* This was mostly downloaded from: https://use.typekit.net/icq3pyl.css
 * Hosting here so that we can set 'font-display: swap' and get a better Lighthouse score.
 */
@font-face {
    font-family: "cubano";
    src: url("https://use.typekit.net/af/a941cf/00000000000000003b9afa9d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/a941cf/00000000000000003b9afa9d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/a941cf/00000000000000003b9afa9d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}
/* GRID */
/* BREAKPOINTS */
/* need to make the width a bit narrower than the breakpoint because otherwise the grid area will be right up against the screen at the cusp of breaking. */
/* COLORS */
/* FONTS */
/* FORMS */
/* SPACING */
/*
 * NOTE: Don't use any postcss-simple-vars inside mixins. postcss-mixins is configured
 * to ignore simple-vars inside mixins (since it can't tell the difference between
 * simple-vars and mixin params).
 *
 * C.f. https://github.com/postcss/postcss-simple-vars/issues/92
 */
/* Universal selectors (*) can target <script> elements
 * and make them display. I don't want that to happen,
 * so overriding display here.
 */
script {
    display: none !important;
}
/* Often need to do this so that container sizing isn't too big at top or bottom because
 * of child margins.
 */
*:first-child { margin-top: 0; }
*:last-child { margin-bottom: 0; }
/* after this, with some changes, from: https://dev.to/hankchizljaw/a-modern-css-reset-6p3 */
/* Box sizing rules */
*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
html {
    font-size: 100%;
}
body {
    margin: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    min-height: 100vh;
}
/* Make images easier to work with */
img {
    max-width: 100%;
    display: block;
    height: auto;
}
/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}
address {
    font-style: normal;
}
/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        -webkit-animation-duration: 0.01ms !important;
                animation-duration: 0.01ms !important;
        -webkit-animation-iteration-count: 1 !important;
                animation-iteration-count: 1 !important;
        -webkit-transition-duration: 0.01ms !important;
                transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
html {
    /* don't add scroll-behavior: smooth here. there's a weird issue with the modals where the page scrolls to a random place after they're closed. removing smooth scrolling fixes it. */
}
body {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.9375rem;
    letter-spacing: 0.0125rem;
    line-height: 1.43;
    color: #151824;
}
/* Remove outline on mouse focus. Class set using the focus-visible library */
.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}
[dir="ltr"] p,[dir="ltr"] 
.p {
    margin-left: 0px;
}
[dir="rtl"] p,[dir="rtl"] 
.p {
    margin-right: 0px;
}
[dir="ltr"] p,[dir="ltr"] 
.p {
    margin-right: 0px;
}
[dir="rtl"] p,[dir="rtl"] 
.p {
    margin-left: 0px;
}
p,
.p {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
}
button.m--as-link {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    display: inline;
}
button.m--as-link,
a,
a:visited,
a:hover,
a:active {
    font-weight: 600;
    text-decoration: underline;
}
button.m--as-link,
a,
a:visited {
    color: #21409A;
}
button.m--as-link:hover, button.m--as-link:active, a:hover, a:active {
    color: #8BBBFF;
}
h1 {
    font-size: 1.75rem;
    color: #21409A;
    font-weight: 700;
    line-height: 1.3
}
h2 {
    font-size: 1.5rem;
}
h3 {
    font-size: 1.125rem;
}
.h--xl--white {
    color: white;
    text-transform: uppercase;
    font-family: "Cubano", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 2.25rem;
    line-height: 1.1
}
@media(min-width: 768px) {
.h--xl--white {
        font-size: 3.75rem
}
    }
.h--md--blue {
    color: #21409A;
    text-transform: uppercase;
    font-family: "Cubano", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.75rem;
    line-height: 1.15;
}
p.description {
    font-style: italic;
}
/* only display at --header-mobile breakpoint */
@media(min-width: 911px) {
.at--hm { display: none
} }
/* only display at --header-desktop breakpoint */
@media(max-width: 910px) {
.at--hd { display: none
} }
.m--pointer {
    cursor: pointer;
}
.m--size--smaller {
    font-size: 0.8125rem;
}
.m--size--largish {
    font-size: 1.25rem;
}
.m--gray--medium {
    color: #898B91;
}
.m--center {
    text-align: center;
}
.m--gradient--blue--medium {
    background: -webkit-gradient(linear, left top, left bottom, from(#929DBE), to(#223C80));
    background: linear-gradient(#929DBE, #223C80);
}
.m--bg--img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -9;
}
.m--bg--img img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover;
    }
.m--lift-hover,
.m--lift-hover-mild {
    position: relative;
    top: 0;
    -webkit-transition: top 200ms ease, -webkit-box-shadow 200ms ease;
    transition: top 200ms ease, -webkit-box-shadow 200ms ease;
    transition: top 200ms ease, box-shadow 200ms ease;
    transition: top 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease
}
.m--lift-hover:hover, .m--lift-hover-mild:hover {
        -webkit-box-shadow: 0 10px 13px 0 rgba(0, 0, 0, 0.2);
                box-shadow: 0 10px 13px 0 rgba(0, 0, 0, 0.2);
        top: -3px;
    }
.m--lift-hover-mild:hover {
        -webkit-box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.2);
                box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.2);
        top: -2px;
    }
.m--clickable {
    cursor: pointer;
}
.m--text-center {
    text-align: center;
}
.c--back {
    display: block;
    font-size: 0.875rem;
    margin-top: 10px
}
@media(min-width: 576px) {
.c--back {
        margin: 0
}
    }
.c--back::before {
        content: '';
        display: inline-block;
        position: relative;
        top: -1px; /* better alignment */
        width: 7px;
        height: 7px;
        margin-right: 7px;
        border-top: 2px solid #21409A;
        border-left: 2px solid #21409A;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }
.c--back:hover::before {
        border-color: #8BBBFF;
    }
.m--list li {
        margin-bottom: 15px
    }
.grid {
    margin: 0 auto;
}
.row {
    margin: 0 -15px;
}
.row::after {
        content: "";
        display: table;
        clear: both;
    }
.col-sm100,
.col-sm80,
.col-sm75,
.col-sm70,
.col-sm66,
.col-sm60,
.col-sm50,
.col-sm55,
.col-sm40,
.col-sm45,
.col-sm33,
.col-sm30,
.col-sm25,
.col-sm20,
.col-sm10,
.col-md100,
.col-md80,
.col-md75,
.col-md70,
.col-md66,
.col-md60,
.col-md50,
.col-md55,
.col-md40,
.col-md45,
.col-md33,
.col-md30,
.col-md25,
.col-md20,
.col-md10,
.col-lg100,
.col-lg80,
.col-lg75,
.col-lg70,
.col-lg66,
.col-lg60,
.col-lg50,
.col-lg55,
.col-lg40,
.col-lg45,
.col-lg33,
.col-lg30,
.col-lg25,
.col-lg20,
.col-lg10,
.col100,
.col80,
.col75,
.col70,
.col66,
.col60,
.col50,
.col55,
.col40,
.col45,
.col33,
.col30,
.col25,
.col20,
.col10
{
    width: 100%;
    float: left;
    padding: 0 15px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: block;
}
/* spacers */
.sp-sm100,
.sp-sm80,
.sp-sm75,
.sp-sm70,
.sp-sm66,
.sp-sm60,
.sp-sm50,
.sp-sm55,
.sp-sm40,
.sp-sm45,
.sp-sm33,
.sp-sm30,
.sp-sm25,
.sp-sm20,
.sp-sm10,
.sp-sm5,
.sp-md100,
.sp-md80,
.sp-md75,
.sp-md70,
.sp-md66,
.sp-md60,
.sp-md50,
.sp-md55,
.sp-md40,
.sp-md45,
.sp-md33,
.sp-md30,
.sp-md25,
.sp-md20,
.sp-md10,
.sp-md5,
.sp-lg100,
.sp-lg80,
.sp-lg75,
.sp-lg70,
.sp-lg66,
.sp-lg60,
.sp-lg50,
.sp-lg55,
.sp-lg40,
.sp-lg45,
.sp-lg33,
.sp-lg30,
.sp-lg25,
.sp-lg20,
.sp-lg10,
.sp-lg5,
.sp100,
.sp80,
.sp75,
.sp70,
.sp66,
.sp60,
.sp50,
.sp55,
.sp40,
.sp45,
.sp33,
.sp30,
.sp25,
.sp20,
.sp10,
.sp5
{
    float: left;
    padding: 0 15px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: block;
    height: 1px;
    content: '';
}
.col100, .sp100 { width: 100%; }
.col80, .sp80 { width: 80%; }
.col75, .sp75 { width: 75%; }
.col70, .sp70 { width: 70%; }
.col66, .sp66 { width: 66.66%; }
.col60, .sp60 { width: 60%; }
.col50, .sp50 { width: 50%; }
.col55, .sp55 { width: 55%; }
.col40, .sp40 { width: 40%; }
.col45, .sp45 { width: 45%; }
.col33, .sp33 { width: 33.33%; }
.col30, .sp30 { width: 30%; }
.col25, .sp25 { width: 25%; }
.col20, .sp20 { width: 20%; }
.col10, .sp10 { width: 10%; }
.sp5 { width: 5%; }
@media (max-width: 575px) {
    .grid { width: 100%; }

    .row { margin: 0; }

    .col-sm100,
    .col-sm80,
    .col-sm75,
    .col-sm70,
    .col-sm66,
    .col-sm60,
    .col-sm50,
    .col-sm55,
    .col-sm40,
    .col-sm45,
    .col-sm33,
    .col-sm30,
    .col-sm25,
    .col-sm20,
    .col-sm10,
    .col-md100,
    .col-md80,
    .col-md75,
    .col-md70,
    .col-md66,
    .col-md60,
    .col-md50,
    .col-md55,
    .col-md40,
    .col-md45,
    .col-md33,
    .col-md30,
    .col-md25,
    .col-md20,
    .col-md10,
    .col-lg100,
    .col-lg80,
    .col-lg75,
    .col-lg70,
    .col-lg66,
    .col-lg60,
    .col-lg50,
    .col-lg55,
    .col-lg40,
    .col-lg45,
    .col-lg33,
    .col-lg30,
    .col-lg25,
    .col-lg20,
    .col-lg10,
    .col100,
    .col80,
    .col75,
    .col70,
    .col66,
    .col60,
    .col50,
    .col55,
    .col40,
    .col45,
    .col33,
    .col30,
    .col25,
    .col20,
    .col10
    {
        /* turns all columns into a single mobile column */
        width: 100%;
        float: none;
        padding: 0 10px;
    }

    .sp-sm100,
    .sp-sm80,
    .sp-sm75,
    .sp-sm70,
    .sp-sm66,
    .sp-sm60,
    .sp-sm50,
    .sp-sm55,
    .sp-sm40,
    .sp-sm45,
    .sp-sm33,
    .sp-sm30,
    .sp-sm25,
    .sp-sm20,
    .sp-sm10,
    .sp-sm5,
    .sp-md100,
    .sp-md80,
    .sp-md75,
    .sp-md70,
    .sp-md66,
    .sp-md60,
    .sp-md50,
    .sp-md55,
    .sp-md40,
    .sp-md45,
    .sp-md33,
    .sp-md30,
    .sp-md25,
    .sp-md20,
    .sp-md10,
    .sp-md5,
    .sp-lg100,
    .sp-lg80,
    .sp-lg75,
    .sp-lg70,
    .sp-lg66,
    .sp-lg60,
    .sp-lg50,
    .sp-lg55,
    .sp-lg40,
    .sp-lg45,
    .sp-lg33,
    .sp-lg30,
    .sp-lg25,
    .sp-lg20,
    .sp-lg10,
    .sp-lg5,
    .sp100,
    .sp80,
    .sp75,
    .sp70,
    .sp66,
    .sp60,
    .sp50,
    .sp55,
    .sp40,
    .sp45,
    .sp33,
    .sp30,
    .sp25,
    .sp20,
    .sp10,
    .sp5
    {
        display: none;
    }
}
@media (min-width: 576px) {
    .grid { width: 546px; }

    .col-sm100, .sp-sm100 { width: 100%; }
    .col-sm80, .sp-sm80 { width: 80%; }
    .col-sm75, .sp-sm75 { width: 75%; }
    .col-sm70, .sp-sm70 { width: 70%; }
    .col-sm66, .sp-sm66 { width: 66.66%; }
    .col-sm60, .sp-sm60 { width: 60%; }
    .col-sm50, .sp-sm50 { width: 50%; }
    .col-sm55, .sp-sm55 { width: 55%; }
    .col-sm40, .sp-sm40 { width: 40%; }
    .col-sm45, .sp-sm45 { width: 45%; }
    .col-sm33, .sp-sm33 { width: 33.33%; }
    .col-sm30, .sp-sm30 { width: 30%; }
    .col-sm25, .sp-sm25 { width: 25%; }
    .col-sm20, .sp-sm20 { width: 20%; }
    .col-sm10, .sp-sm10 { width: 10%; }
    .sp-sm5 { width: 5%; }
}
@media (min-width: 768px) {
    .grid { width: 738px; }

    .col-md100, .sp-md100 { width: 100%; }
    .col-md80, .sp-md80 { width: 80%; }
    .col-md75, .sp-md75 { width: 75%; }
    .col-md70, .sp-md70 { width: 70%; }
    .col-md66, .sp-md66 { width: 66.66%; }
    .col-md60, .sp-md60 { width: 60%; }
    .col-md50, .sp-md50 { width: 50%; }
    .col-md55, .sp-md55 { width: 55%; }
    .col-md40, .sp-md40 { width: 40%; }
    .col-md45, .sp-md45 { width: 45%; }
    .col-md33, .sp-md33 { width: 33.33%; }
    .col-md30, .sp-md30 { width: 30%; }
    .col-md25, .sp-md25 { width: 25%; }
    .col-md20, .sp-md20 { width: 20%; }
    .col-md10, .sp-md10 { width: 10%; }
    .sp-md5 { width: 5%; }
}
@media (min-width: 992px) {
    .grid { width: 962px; }

    .col-lg100, .sp-lg100 { width: 100%; }
    .col-lg80, .sp-lg80 { width: 80%; }
    .col-lg75, .sp-lg75 { width: 75%; }
    .col-lg70, .sp-lg70 { width: 70%; }
    .col-lg66, .sp-lg66 { width: 66.66%; }
    .col-lg60, .sp-lg60 { width: 60%; }
    .col-lg50, .sp-lg50 { width: 50%; }
    .col-lg55, .sp-lg55 { width: 55%; }
    .col-lg40, .sp-lg40 { width: 40%; }
    .col-lg45, .sp-lg45 { width: 45%; }
    .col-lg33, .sp-lg33 { width: 33.33%; }
    .col-lg30, .sp-lg30 { width: 30%; }
    .col-lg25, .sp-lg25 { width: 25%; }
    .col-lg20, .sp-lg20 { width: 20%; }
    .col-lg10, .sp-lg10 { width: 10%; }
    .sp-lg5 { width: 5%; }
}
@media (min-width: 1200px) {
    .grid { width: 1170px; }
}
.pad-left-gutter {
    padding-left: 10px
}
@media (min-width: 576px) {
.pad-left-gutter { padding-left: calc(50vw - 273px)
} }
@media (min-width: 768px) {
.pad-left-gutter { padding-left: calc(50vw - 369px)
} }
@media (min-width: 992px) {
.pad-left-gutter { padding-left: calc(50vw - 481px)
} }
@media (min-width: 1200px) {
.pad-left-gutter { padding-left: calc(50vw - 585px)
} }
.pad-right-gutter {
    padding-right: 10px
}
@media (min-width: 576px) {
.pad-right-gutter { padding-right: calc(50vw - 273px)
} }
@media (min-width: 768px) {
.pad-right-gutter { padding-right: calc(50vw - 369px)
} }
@media (min-width: 992px) {
.pad-right-gutter { padding-right: calc(50vw - 481px)
} }
@media (min-width: 1200px) {
.pad-right-gutter { padding-right: calc(50vw - 585px)
} }
.page-padding-tb {
    padding-top: 30px;
    padding-bottom: 30px
}
@media(max-width: 575px) {
.page-padding-tb {
        padding-top: 30px;
        padding-bottom: 30px
}
    }
.page-margin-tb {
    margin-top: 30px;
    margin-bottom: 30px
}
@media(max-width: 575px) {
.page-margin-tb {
        margin-top: 30px;
        margin-bottom: 30px
}
    }
/*
 * These styles make it easy to show or hide elements on certain screen sizes.
 *
 * Styles starting with t- mean up to and including the indicated size, but not
 * bigger (e.g. t-sm means up to and including small screen size).
 *
 * Styles starting with a- mean after and including the indicated size, but not
 * smaller (e.g. a-sm means after and including small screen size).
 *
 * Think of a- to mean "at and after" and t- to mean "up to and including".
 *
 * So if you wanted to display this element on small screens and below:
 *
 * <div class='t-sm'></div>
 *
 * And this element on screens bigger than small (e.g. medium and bigger)
 *
 * <div class='a-md'></div>
 */
@media (min-width: 576px) {
.t-xs { display: none !important } }
@media (min-width: 768px) {
.t-sm { display: none !important } }
@media (min-width: 992px) {
.t-md { display: none !important } }
@media (max-width: 575px) {
.a-sm { display: none !important } }
@media (max-width: 767px) {
.a-md { display: none !important } }
@media (max-width: 991px) {
.a-lg { display: none !important } }
@media (max-width: 1199px) {
.a-xl { display: none !important } }
/* NOTE: The Cybersource form fields are styled in client/components/FormCybersourceField.js */
form .flex-microform,
    form input[type='text'],
    form input[type='email'],
    form input[type='number'],
    form input[type='password'],
    form input[type='search'],
    form input[type='tel'],
    form input[type='url'],
    form select,
    form textarea {
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 9px 8px;
        border: 1px solid #4B62A0;
        border-radius: 3px;
        font-size: 0.9375rem;
        color: #151824;
        background: white;
        -webkit-box-shadow: 0 1px 2px -1px white, inset 0 1px 3px 0 #666C82;
                box-shadow: 0 1px 2px -1px white, inset 0 1px 3px 0 #666C82
    }
.btn,
.btn:visited {
    display: inline-block;
    border-radius: 3px;
    -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
    font-weight: 600;
    padding: 12px 54px;
    border: 0;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease
}
.btn:hover, .btn:visited:hover {
        text-decoration: none;
    }
.btn--slim {
    padding: 10px 14px;
}
.btn--slim--lr {
    padding-left: 14px;
    padding-right: 14px;
}
.btn--block {
    width: 100%;
}
.btn--large {
    font-size: 1.125rem;
    font-weight: 600;
}
.btn--white,
.btn--white:visited {
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #151824;
    padding: 12px 10px;
    background: white;
    border: 1px solid #21409A
}
.btn--white:hover, .btn--white:visited:hover {
        color: #151824;
        background: #6BA8FF;
    }
.btn--blue--light,
.btn--blue--light:visited {
    color: white;
    background: #3177CA;
    font-style: italic
}
.btn--blue--light:hover, .btn--blue--light:visited:hover {
        color: white;
        background: #6BA8FF;
    }
.btn--blue--dark,
.btn--blue--dark:visited {
    color: white;
    background: #21409A
}
.btn--blue--dark:hover, .btn--blue--dark:visited:hover {
        color: white;
        background: #6BA8FF;
    }
.btn--yellow,
.btn--yellow:visited {
    color: #21409A;
    background: #FFDD15
}
.btn--yellow:hover, .btn--yellow:visited:hover {
        color: #21409A;
        background: #FFED15;
    }
.btn--cancel,
.btn--cancel:visited {
    color: white;
    background: #9295A1;
    font-style: italic
}
.btn--cancel:hover, .btn--cancel:visited:hover {
        color: white;
        background: #C5C6C9;
    }
.btn--disabled, 
.btn--disabled:visited, 
.btn:disabled {
    background: #E1E2E6;
    color: #666C82
}
.btn--disabled:hover, .btn--disabled:visited:hover, .btn:disabled:hover {
        color: #666C82;
        -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
                box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2); /* keep the box-shadow */
    }
.btn:disabled {
    cursor: not-allowed;
}
/* removes common button styling */
button.m--no-btn {
    border: 0;
    padding: 0;
    background: none;
}
.c--photo-flag {
    position: relative;
    overflow: hidden;
}
.c--photo-flag::after {
    position: absolute;
    font-weight: 700;
    left: -18px;
    top: 11px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    font-size: 0.75rem;
    padding: 0 25px;
    z-index: 0;
}
.c--photo-flag.m--flag-new::after {
    content: 'NEW';
    color: #21409A;
    background: #FFDD15;
}
.c--photo-flag.m--flag-sale::after {
    content: 'NEW';
    color: #21409A;
    background: #FFDD15;
}

