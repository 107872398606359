/*
 * NOTE: Don't use any postcss-simple-vars inside mixins. postcss-mixins is configured
 * to ignore simple-vars inside mixins (since it can't tell the difference between
 * simple-vars and mixin params).
 *
 * C.f. https://github.com/postcss/postcss-simple-vars/issues/92
 */

@define-mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}
