/* This was mostly downloaded from: https://use.typekit.net/icq3pyl.css
 * Hosting here so that we can set 'font-display: swap' and get a better Lighthouse score.
 */

@import url("https://p.typekit.net/p.css?s=1&k=icq3pyl&ht=tk&f=15493.15495&a=3703564&app=typekit&e=css");

@font-face {
    font-family: "cubano";
    src: url("https://use.typekit.net/af/a941cf/00000000000000003b9afa9d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/a941cf/00000000000000003b9afa9d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/a941cf/00000000000000003b9afa9d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}
