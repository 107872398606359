.grid {
    margin: 0 auto;
}

.row {
    margin: 0 calc(-1 * $gutter);

    @mixin clearfix;
}

.col-sm100,
.col-sm80,
.col-sm75,
.col-sm70,
.col-sm66,
.col-sm60,
.col-sm50,
.col-sm55,
.col-sm40,
.col-sm45,
.col-sm33,
.col-sm30,
.col-sm25,
.col-sm20,
.col-sm10,
.col-md100,
.col-md80,
.col-md75,
.col-md70,
.col-md66,
.col-md60,
.col-md50,
.col-md55,
.col-md40,
.col-md45,
.col-md33,
.col-md30,
.col-md25,
.col-md20,
.col-md10,
.col-lg100,
.col-lg80,
.col-lg75,
.col-lg70,
.col-lg66,
.col-lg60,
.col-lg50,
.col-lg55,
.col-lg40,
.col-lg45,
.col-lg33,
.col-lg30,
.col-lg25,
.col-lg20,
.col-lg10,
.col100,
.col80,
.col75,
.col70,
.col66,
.col60,
.col50,
.col55,
.col40,
.col45,
.col33,
.col30,
.col25,
.col20,
.col10
{
    width: 100%;
    float: left;
    padding: 0 $gutter;
    box-sizing: border-box;
    display: block;
}

/* spacers */
.sp-sm100,
.sp-sm80,
.sp-sm75,
.sp-sm70,
.sp-sm66,
.sp-sm60,
.sp-sm50,
.sp-sm55,
.sp-sm40,
.sp-sm45,
.sp-sm33,
.sp-sm30,
.sp-sm25,
.sp-sm20,
.sp-sm10,
.sp-sm5,
.sp-md100,
.sp-md80,
.sp-md75,
.sp-md70,
.sp-md66,
.sp-md60,
.sp-md50,
.sp-md55,
.sp-md40,
.sp-md45,
.sp-md33,
.sp-md30,
.sp-md25,
.sp-md20,
.sp-md10,
.sp-md5,
.sp-lg100,
.sp-lg80,
.sp-lg75,
.sp-lg70,
.sp-lg66,
.sp-lg60,
.sp-lg50,
.sp-lg55,
.sp-lg40,
.sp-lg45,
.sp-lg33,
.sp-lg30,
.sp-lg25,
.sp-lg20,
.sp-lg10,
.sp-lg5,
.sp100,
.sp80,
.sp75,
.sp70,
.sp66,
.sp60,
.sp50,
.sp55,
.sp40,
.sp45,
.sp33,
.sp30,
.sp25,
.sp20,
.sp10,
.sp5
{
    float: left;
    padding: 0 $gutter;
    box-sizing: border-box;
    display: block;
    height: 1px;
    content: '';
}

.col100, .sp100 { width: 100%; }
.col80, .sp80 { width: 80%; }
.col75, .sp75 { width: 75%; }
.col70, .sp70 { width: 70%; }
.col66, .sp66 { width: 66.66%; }
.col60, .sp60 { width: 60%; }
.col50, .sp50 { width: 50%; }
.col55, .sp55 { width: 55%; }
.col40, .sp40 { width: 40%; }
.col45, .sp45 { width: 45%; }
.col33, .sp33 { width: 33.33%; }
.col30, .sp30 { width: 30%; }
.col25, .sp25 { width: 25%; }
.col20, .sp20 { width: 20%; }
.col10, .sp10 { width: 10%; }
.sp5 { width: 5%; }

@media (--xs) {
    .grid { width: 100%; }

    .row { margin: 0; }

    .col-sm100,
    .col-sm80,
    .col-sm75,
    .col-sm70,
    .col-sm66,
    .col-sm60,
    .col-sm50,
    .col-sm55,
    .col-sm40,
    .col-sm45,
    .col-sm33,
    .col-sm30,
    .col-sm25,
    .col-sm20,
    .col-sm10,
    .col-md100,
    .col-md80,
    .col-md75,
    .col-md70,
    .col-md66,
    .col-md60,
    .col-md50,
    .col-md55,
    .col-md40,
    .col-md45,
    .col-md33,
    .col-md30,
    .col-md25,
    .col-md20,
    .col-md10,
    .col-lg100,
    .col-lg80,
    .col-lg75,
    .col-lg70,
    .col-lg66,
    .col-lg60,
    .col-lg50,
    .col-lg55,
    .col-lg40,
    .col-lg45,
    .col-lg33,
    .col-lg30,
    .col-lg25,
    .col-lg20,
    .col-lg10,
    .col100,
    .col80,
    .col75,
    .col70,
    .col66,
    .col60,
    .col50,
    .col55,
    .col40,
    .col45,
    .col33,
    .col30,
    .col25,
    .col20,
    .col10
    {
        /* turns all columns into a single mobile column */
        width: 100%;
        float: none;
        padding: 0 $page-lr-margin-xs;
    }

    .sp-sm100,
    .sp-sm80,
    .sp-sm75,
    .sp-sm70,
    .sp-sm66,
    .sp-sm60,
    .sp-sm50,
    .sp-sm55,
    .sp-sm40,
    .sp-sm45,
    .sp-sm33,
    .sp-sm30,
    .sp-sm25,
    .sp-sm20,
    .sp-sm10,
    .sp-sm5,
    .sp-md100,
    .sp-md80,
    .sp-md75,
    .sp-md70,
    .sp-md66,
    .sp-md60,
    .sp-md50,
    .sp-md55,
    .sp-md40,
    .sp-md45,
    .sp-md33,
    .sp-md30,
    .sp-md25,
    .sp-md20,
    .sp-md10,
    .sp-md5,
    .sp-lg100,
    .sp-lg80,
    .sp-lg75,
    .sp-lg70,
    .sp-lg66,
    .sp-lg60,
    .sp-lg50,
    .sp-lg55,
    .sp-lg40,
    .sp-lg45,
    .sp-lg33,
    .sp-lg30,
    .sp-lg25,
    .sp-lg20,
    .sp-lg10,
    .sp-lg5,
    .sp100,
    .sp80,
    .sp75,
    .sp70,
    .sp66,
    .sp60,
    .sp50,
    .sp55,
    .sp40,
    .sp45,
    .sp33,
    .sp30,
    .sp25,
    .sp20,
    .sp10,
    .sp5
    {
        display: none;
    }
}

@media (--sm) {
    .grid { width: $width--sm; }

    .col-sm100, .sp-sm100 { width: 100%; }
    .col-sm80, .sp-sm80 { width: 80%; }
    .col-sm75, .sp-sm75 { width: 75%; }
    .col-sm70, .sp-sm70 { width: 70%; }
    .col-sm66, .sp-sm66 { width: 66.66%; }
    .col-sm60, .sp-sm60 { width: 60%; }
    .col-sm50, .sp-sm50 { width: 50%; }
    .col-sm55, .sp-sm55 { width: 55%; }
    .col-sm40, .sp-sm40 { width: 40%; }
    .col-sm45, .sp-sm45 { width: 45%; }
    .col-sm33, .sp-sm33 { width: 33.33%; }
    .col-sm30, .sp-sm30 { width: 30%; }
    .col-sm25, .sp-sm25 { width: 25%; }
    .col-sm20, .sp-sm20 { width: 20%; }
    .col-sm10, .sp-sm10 { width: 10%; }
    .sp-sm5 { width: 5%; }
}

@media (--md) {
    .grid { width: $width--md; }

    .col-md100, .sp-md100 { width: 100%; }
    .col-md80, .sp-md80 { width: 80%; }
    .col-md75, .sp-md75 { width: 75%; }
    .col-md70, .sp-md70 { width: 70%; }
    .col-md66, .sp-md66 { width: 66.66%; }
    .col-md60, .sp-md60 { width: 60%; }
    .col-md50, .sp-md50 { width: 50%; }
    .col-md55, .sp-md55 { width: 55%; }
    .col-md40, .sp-md40 { width: 40%; }
    .col-md45, .sp-md45 { width: 45%; }
    .col-md33, .sp-md33 { width: 33.33%; }
    .col-md30, .sp-md30 { width: 30%; }
    .col-md25, .sp-md25 { width: 25%; }
    .col-md20, .sp-md20 { width: 20%; }
    .col-md10, .sp-md10 { width: 10%; }
    .sp-md5 { width: 5%; }
}

@media (--lg) {
    .grid { width: $width--lg; }

    .col-lg100, .sp-lg100 { width: 100%; }
    .col-lg80, .sp-lg80 { width: 80%; }
    .col-lg75, .sp-lg75 { width: 75%; }
    .col-lg70, .sp-lg70 { width: 70%; }
    .col-lg66, .sp-lg66 { width: 66.66%; }
    .col-lg60, .sp-lg60 { width: 60%; }
    .col-lg50, .sp-lg50 { width: 50%; }
    .col-lg55, .sp-lg55 { width: 55%; }
    .col-lg40, .sp-lg40 { width: 40%; }
    .col-lg45, .sp-lg45 { width: 45%; }
    .col-lg33, .sp-lg33 { width: 33.33%; }
    .col-lg30, .sp-lg30 { width: 30%; }
    .col-lg25, .sp-lg25 { width: 25%; }
    .col-lg20, .sp-lg20 { width: 20%; }
    .col-lg10, .sp-lg10 { width: 10%; }
    .sp-lg5 { width: 5%; }
}

@media (--xl) {
    .grid { width: $width--xl; }
}

.pad-left-gutter {
    padding-left: $page-lr-margin-xs;

    @media (--sm) { padding-left: calc((100vw - $width--sm) / 2); }
    @media (--md) { padding-left: calc((100vw - $width--md) / 2); }
    @media (--lg) { padding-left: calc((100vw - $width--lg) / 2); }
    @media (--xl) { padding-left: calc((100vw - $width--xl) / 2); }
}

.pad-right-gutter {
    padding-right: $page-lr-margin-xs;

    @media (--sm) { padding-right: calc((100vw - $width--sm) / 2); }
    @media (--md) { padding-right: calc((100vw - $width--md) / 2); }
    @media (--lg) { padding-right: calc((100vw - $width--lg) / 2); }
    @media (--xl) { padding-right: calc((100vw - $width--xl) / 2); }
}

.page-padding-tb {
    padding-top: $page-tb-margin;
    padding-bottom: $page-tb-margin;

    @media(--xs) {
        padding-top: $page-tb-margin-xs;
        padding-bottom: $page-tb-margin-xs;
    }
}

.page-margin-tb {
    margin-top: $page-tb-margin;
    margin-bottom: $page-tb-margin;

    @media(--xs) {
        margin-top: $page-tb-margin-xs;
        margin-bottom: $page-tb-margin-xs;
    }
}

/*
 * These styles make it easy to show or hide elements on certain screen sizes.
 *
 * Styles starting with t- mean up to and including the indicated size, but not
 * bigger (e.g. t-sm means up to and including small screen size).
 *
 * Styles starting with a- mean after and including the indicated size, but not
 * smaller (e.g. a-sm means after and including small screen size).
 *
 * Think of a- to mean "at and after" and t- to mean "up to and including".
 *
 * So if you wanted to display this element on small screens and below:
 *
 * <div class='t-sm'></div>
 *
 * And this element on screens bigger than small (e.g. medium and bigger)
 *
 * <div class='a-md'></div>
 */

.t-xs { @media (--sm) { display: none !important; } }
.t-sm { @media (--md) { display: none !important; } }
.t-md { @media (--lg) { display: none !important; } }

.a-sm { @media (max-width: calc($screen-sm-min - 1px)) { display: none !important; } }
.a-md { @media (max-width: calc($screen-md-min - 1px)) { display: none !important; } }
.a-lg { @media (max-width: calc($screen-lg-min - 1px)) { display: none !important; } }
.a-xl { @media (max-width: calc($screen-xl-min - 1px)) { display: none !important; } }
