html {
    /* don't add scroll-behavior: smooth here. there's a weird issue with the modals where the page scrolls to a random place after they're closed. removing smooth scrolling fixes it. */
}

body {
    font-family: $family--sans;
    font-weight: $weight--regular;
    font-size: $size--regular;
    letter-spacing: $letter-spacing--regular;
    line-height: $line-height--regular;
    color: $c--body--text;
}

/* Remove outline on mouse focus. Class set using the focus-visible library */
.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

p,
.p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

button.m--as-link {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    display: inline;
}

button.m--as-link,
a,
a:visited,
a:hover,
a:active {
    font-weight: $weight--medium;
    text-decoration: underline;
}

button.m--as-link,
a, a:visited {
    color: $c--link--body;
}

button.m--as-link:hover, button.m--as-link:active,
a:hover, a:active {
    color: $c--link--body--hover;
}

h1 {
    font-size: $size--largest;
    color: $c--blue--darker;
    font-weight: $weight--bold;
    line-height: 1.3
}

h2 {
    font-size: $size--larger;
}

h3 {
    font-size: $size--medium;
}

.h--xl--white {
    color: white;
    text-transform: uppercase;
    font-family: $family--sans-alt;
    font-size: 36px;
    line-height: 1.1;

    @media(--md) {
        font-size: 60px;
    }
}

.h--md--blue {
    color: $c--blue--darker;
    text-transform: uppercase;
    font-family: $family--sans-alt;
    font-size: 28px;
    line-height: 1.15;
}

p.description {
    font-style: italic;
}

/* only display at --header-mobile breakpoint */
.at--hm {
    @media(--header-desktop) { display: none; }
}

/* only display at --header-desktop breakpoint */
.at--hd {
    @media(--header-mobile) { display: none; }
}

.m--pointer {
    cursor: pointer;
}

.m--size--smaller {
    font-size: $size--smaller;
}

.m--size--largish {
    font-size: $size--largish;
}

.m--gray--medium {
    color: #898B91;
}

.m--center {
    text-align: center;
}

.m--gradient--blue--medium {
    background: linear-gradient(#929DBE, #223C80);
}

.m--bg--img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -9;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.m--lift-hover,
.m--lift-hover-mild {
    position: relative;
    top: 0;
    transition: top 200ms ease, box-shadow 200ms ease;

    &:hover {
        box-shadow: 0 10px 13px 0 rgba(0, 0, 0, 0.2);
        top: -3px;
    }
}

.m--lift-hover-mild {
    &:hover {
        box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.2);
        top: -2px;
    }
}

.m--clickable {
    cursor: pointer;
}

.m--text-center {
    text-align: center;
}

.c--back {
    display: block;
    font-size: $size--small;
    margin-top: 10px;

    @media(--sm) {
        margin: 0;
    }

    &::before {
        content: '';
        display: inline-block;
        position: relative;
        top: -1px; /* better alignment */
        width: 7px;
        height: 7px;
        margin-right: 7px;
        border-top: 2px solid $c--link--body;
        border-left: 2px solid $c--link--body;
        transform: rotate(-45deg);
    }

    &:hover::before {
        border-color: $c--link--body--hover;
    }
}

.m--list {
    li {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
